.c-sh-care-plan-table {
  .c-responsive-table-header {
    top: calc(4.875 * #{$els-space-1x1o4});
  }

  .c-responsive-table-body-cell--expandable-expanded {
    .c-sh-care-plan-table__cell-category-name {
      border-bottom: $els-thick-keyline-width solid $els-color-primary;
    }
  }

  .c-progress-chart__left-percent-content {
    width: calc(#{$els-space} * 14);
  }

  .c-progress-chart__left-percent-value {
    width: $els-space-3x;
  }

  .c-responsive-table-body-row-wrapper--expand {
    @extend .u-els-background-color-white;

    .c-responsive-table-body-cell--sticky {
      @extend .u-els-background-color-white;
    }
  }

  &__average-score-column {
    &-header {
      display: flex;
      justify-content: flex-start;
      padding-left: $els-space-1x !important;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    position: relative;

    &-category-name {
      display: flex;
      justify-content: space-between;
      margin-right: calc(#{$els-space-1o2} + #{$els-space-1o8});
      @extend .u-els-color-black, .u-els-bold;

      &-alert-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @extend .u-els-color-warn;
      }
    }
  }

  &__cell-category-label-children {
    padding-left: calc(#{$els-space} * 3.5);
  }

  &__cell-category-label-grant-children {
    padding-left: calc(#{$space-6x} + 0.875 * #{$els-space});
  }
}
