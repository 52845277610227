$children-cell-padding-left: calc(#{$els-space-5x} + 0.625 * #{$els-space});

.c-sh-subjective-data-collection-category-table {
  &__expand-row {
    .c-responsive-table-body-cell--expandable-expanded {
      .c-sh-subjective-data-collection-category-table__cell-category-name:not(:first-child) {
        border-bottom: $els-thick-keyline-width solid $els-color-primary;
      }
    }
  }

  &__header-average-score {
    .c-responsive-table__sort-button-text {
      padding-left: calc(#{$els-space} * 3.5);
    }
  }

  &__row-children {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: $children-cell-padding-left;
      top: 0;
      height: $els-thin-keyline-width;
      width: calc(100% - #{$children-cell-padding-left});
      border-top: $els-thin-keyline-width solid $els-color-n2;
      z-index: 2;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    position: relative;
    &-category-name {
      display: flex;
      justify-content: space-between;
      @extend .u-els-color-black, .u-els-bold;
    }
    &-category-avg-score {
      position: relative;
      .c-progress-chart__left-percent-content {
        width: calc(#{$els-space} * 14);
      }

      padding-left: calc(#{$els-space} * 3.5);
      &-alert-icon {
        position: absolute;
        top: 50%;
        left: $els-space-3x;
        transform: translate(-100%, -50%);
        @extend .u-els-color-warn;
      }
    }
    &-category-label {
      &-children {
        padding-left: calc(#{$els-space} * 4.125);
      }
      &-grant-children {
        padding-left: calc(#{$els-space} * 6.875);
      }
    }
  }

  .c-responsive-table-body-row-wrapper--expand {
    @extend .u-els-background-color-white;
    .c-responsive-table-body-cell--sticky {
      @extend .u-els-background-color-white;
    }
  }
}
